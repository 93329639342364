.successfulExamplesWhole{
    /* background-color: rgb(30, 30, 30); */
    /* background-color: #ff7913; */
    min-height: 650px;
    padding-top: 40px;
    padding-bottom: 50px;
    /* overflow: hidden; */
    /* background-color: #ffd596; */
    /* width: 200%; */
    /* border: 5px solid #ff8f2c; */
}

.successfulExamples{
    /* width: 86%; */
    margin: auto;
    /* width: 200%; */
    display: flex;
    
}

.successfulExamplesWhole h2{
   /* color: white; */
   margin-bottom: 10px;
   line-height: 35px;
   font-size: 28px;
   margin-top: 10px;
   font-weight: 700;
}

.successfulExampleSlideOne{
    /* margin-left: 13%;
    margin-right: 13%; */
    /* background-color: green; */
    /* width: 50%; */
    width: 86%;
    margin: auto;

}

.successfulExampleSlideSecond {
    /* margin-left: 13%;
    margin-right: 13%; */
    /* background-color: green; */
    /* width: 50%; */
    width: 86%;
    margin: auto;
}

.successfulExampleSlideOne img{
    width: 90%;
    max-width: 650px;
    margin-top: 25px;
    margin-bottom: 25px;
    /* padding-bottom: 30px; */
}

.slideOneImagesSmall{
    display: none;
}

.successfulExampleSlideOne .intro{
    /* background-color: white; */
    /* width: 86%; */
    margin: auto;
}

.successfulExampleSlideOne .introText{
/* color: white; */
margin: auto;
font-size: 18px;
line-height: 25px;
width: 90%;
margin-top: 25px;
margin-bottom: 20px;
max-width: 900px;
}

.disclaimer{
    color: rgb(180, 180, 180);
    /* max-width: 700px; */
    text-align: left;
    line-height: 22px;
    /* font-size: 14px; */
}

.successfulExampleSlideSecondInner{
    /* width: 86%; */
    margin: auto;
    margin-top: 50px;
    display: flex;
    max-width: max-content;
}

.successfulExampleSlideSecond img{
    max-width: 360px;
    width: 100%;

    /* background-color: pink; */
}

.successfulExampleSlideSecond .caseText{
    /* color: white; */
    /* width: 500px; */
    line-height: 22px;
    max-width: 360px;
    text-align: left;
    margin-top: 20px;
    /* width: 100%; */
}

.successfulExampleSlideSecond .introText{
    /* color: white; */
    margin: auto;
    font-size: 18px;
    line-height: 25px;
    width: 90%;
    margin-top: 25px;
    margin-bottom: 20px;
    max-width: 900px;
}

.successfulExampleCaseOne{
    margin-right: 20px;
    width: 100%;
}

.successfulExampleCaseTwo{
    margin-left: 20px;
    width: 100%;
}

.swiper-pagination{
    position: relative !important;
    margin-top: 20px;
}


@media screen and (max-width: 400px){
    /* .about h1{
        padding-top: 50px;     
    } */
    .successfulExampleSlideOne img{
        width: 100%;
    }
    
}

@media screen and (max-width: 700px){
    /* .about h1{
        padding-top: 50px;     
    } */
    .successfulExampleSlideSecondInner{
        /* width: 86%; */
        display: block;
    }
    .successfulExampleCaseOne{
        margin-right: 0px;
        width: 90%;
        margin: auto;
    }
    
    .successfulExampleCaseTwo{
        width: 90%;

        margin-left: 0px;
        margin: auto;
        margin-top: 40px;
    }
    .successfulExampleSlideSecondInner{
        /* background-color: pink; */
        margin-top: 40px;
    }
    .successfulExampleSlideSecond .introText{
        /* color: white; */
        width: 100%;
    }

    .successfulExampleSlideOne .introText{
        width: 100%;
    }

    .slideOneImagesSmall{
        display: block;
   }

    .slideOneImagesLarge{
       display: none;
    }
    .slideOneImagesSmall {
    }
    .slideOneImagesSmall img{
        padding-bottom: 0px;
        margin-bottom: 20px;
        margin-top: 20px;
    }

    #slideOneImagesSmallOne{
        width: 97%;
        max-width: 305px;
    }
    #slideOneImagesSmallTwo{
        width: 80%;
        max-width: 245px;
    }
    #slideOneImagesSmallThree{
        width: 100%;
        max-width: 320px;
    }
    .successfulExampleSlideSecond img{
        max-width: 300px;
    }
    .successfulExampleSlideSecond .caseText{
        max-width: 300px;
    }
    
    
}