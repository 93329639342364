.postsWhole{
    /* background-color: #ff5c2c; */
    /* background-color: #141414; */
    /* background-image: linear-gradient(45deg, #edaa1b 0%,#ff5c2c 50%,#ff8f2c 100%);  */
    background-color: #f3f3f3;
    /* position: absolute;
    left: 0;
    right: 0; */
    /* margin-top: 30px; */
    /* display: flex; */
    padding-bottom: 5px;
    /* padding-top: 40px; */
    /* padding-top: 30px; */
}

.jobPosts h2{
    font-size: 28px;
    text-align: center;
    margin-top: 5px;
    margin-bottom: 0px;
    font-size: 26px;
    padding-left: calc(5% + 13px);
    padding-right: calc(5% + 13px);
    font-weight: 700;
    line-height: 35px;
    padding-top: 10px;
    padding-bottom: 20px;
    /* color: white */
}

.posts{
    /* display: block;
    margin-top: 20px; */
    /* padding-top: 15px; */
}

.postFilters{
    text-align: left;
}

.postFilters h4{
    margin-bottom: 10px;
    margin-top: 5px;
    line-height: 22px;
    color: #252525;
    /* font-weight: 600; */
}

.postFilters input{
    /* width: 180px; */
    width: 180px;
    border-radius: 3px;
    border: none;
    color: black;
    padding-top: 8px;
    padding-bottom: 8px;
    margin-top: 0px;
    margin-bottom: 15px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 16px;
    display: inline-block;
    border: 1px solid rgb(230, 230, 230);
}

.postFilters select{
    width: 202px;
    border-radius: 3px;
    border: none;
    color: black;
    padding-top: 8px;
    padding-bottom: 8px;
    margin-top: 0px;
    margin-bottom: 15px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 16px;
    display: inline-block;
    border: 1px solid rgb(230, 230, 230);
    background-color: white !important;
    height: 38px !important;
}

.postFilters button{
    width: 202px;
    border-radius: 3px;
    border: none;
    padding-top: 8px;
   padding-bottom: 8px;
    margin-top: 0px;
    margin-bottom: 8px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 16px;
    margin-left: 10px;
    color: #252525;
    background-color: rgb(225, 225, 225);
}

.postsWhole button:hover{
    cursor: pointer;
}

.postsIntroForm{
    text-align: left;
    width: 86%;
    margin: auto;
    line-height: 22px;
}

.postsIntroForm h2{
    margin-bottom: 10px;
    line-height: 35px;
    font-size: 28px;
    margin-top: 10px;
    font-weight: 700;
}

.postsIntroForm h3{
    margin-bottom: 7px;
}

.postsIntroForm p{
    margin-top: 0px;
    margin-bottom: 30px;
}

.postsIntroForm form{
    display: flex;
}

.postsIntroForm input{
    width: calc(100% - 20px);
    border-radius: 3px;
    border: none;
    color: black;
    padding-top: 8px;
    padding-bottom: 8px;
    margin-top: 0px;
    margin-bottom: 8px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 16px;
    border: 1px solid rgb(230, 230, 230);
}

.postsIntroForm button{
    width: 75px;
    border-radius: 3px;
    border: none;
    padding-top: 8px;
    padding-bottom: 8px;
    margin-top: 0px;
    margin-bottom: 8px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 16px;
    margin-left: 10px;
    color: #252525;
    background-color: rgb(225, 225, 225);
}

.post{
    background-color: white;
    width: 86%;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 30px;
    text-align: left;
    border-radius: 10px;
}

.post h3{
    margin-bottom: 0px;
    width: max-content;
    margin-right: 0px;
    margin-top: 0px;
    color: #252525;
}

.post h4{
    margin-bottom: 7px;
    margin-top: 17px;
    color: #252525;
}

.boo{
    display: flex;
    margin-top: 25px;
}

.postInner{
    /* padding: 20px; */
    padding-left: calc(5% + 13px);
    padding-right: calc(5% + 13px);
    padding-top: 30px;
    padding-bottom: 30px;
}

.influencerBioHandle{
    display: inline-block;
    margin-bottom: 0px;
    margin-top: 0px;
    line-height: 22px;
}

.postTitle{
    display: flex;
    align-items:center;
}

.postTitle a{
text-decoration: none;
}

.postTitle a:hover{
    /* background-color: #ff7913; */
    cursor: pointer;
    border-radius: 3px;
    /* color: white; */
    text-decoration: underline;
    text-decoration-thickness: 3px;
    text-decoration-color: #ff7913;
    
}

.postTitle h3:hover{
    /* color: white; */
}

.postRestaurantLocation{
    /* color: rgb(169, 169, 169); */
    /* color: white; */
    /* font-weight: 500; */
        /* border: 1px solid gray; */
    margin-top: 0px;
    /* background-color: rgb(220, 220, 220); */
    /* color: rgb(165, 165, 165);
    padding: 5px 10px 5px 10px; */
    color: gray;
    border-radius: 3px;
    width: max-content;
}

.postTitle p{
margin-bottom: 0px;
margin-left: 10px;
}

.postRestaurantDescription{
    margin-top: 15px;
    line-height: 22px;
    /* font-weight: 500; */
}

.postRestaurantDescription p{
   margin-right: 20px;
}

.postRestaurantImage{
    width: 40%;
    min-width: 210px;
    max-width: 250px;
    /* background-color: yellow; */
}

.postRestaurantImage img{
    width: 100%;
    border-radius: 10px;
    min-width: 210px;
    max-width: 250px;
}

.postRestaurantLinks {
    display: flex;
}

.postRestaurantLinks img{
    width: 25px;
}

.postJobDetails{
    /* background-color: green; */
    /* width: calc(100% - 275px); */
    margin-left: calc( 1.5% + 25px);
    width: 100%;
    /* margin-left: 30px; */
}

.postJobDetailsCreator{
 
}

.postCompensation{
    /* background-color: #ffa083; */
    background-color: white;
    /* border: 2px solid #ff5c2c; */
    border: 1px solid rgb(230, 230, 230);
    background-color: rgb(250, 250, 250);
    /* color: #ff5c2c; */
    color: #252525;
    text-align: center;
    font-weight: 700;
    border-radius: 3px;
    padding-top: 8px;
    padding-bottom: 8px;
    margin-top: 0px;
    margin-bottom: 12px;
}

.postCompensationCreator{
    /* background-color: #ffa083; */
    background-color: white;
    /* border: 2px solid #ff5c2c; */
    border: 1px solid rgb(230, 230, 230);
    background-color: rgb(250, 250, 250);
    /* color: #ff5c2c; */
    color: #252525;
    text-align: center;
    font-weight: 700;
    border-radius: 3px;
    padding-top: 8px;
    padding-bottom: 8px;
    margin-top: 0px;
    margin-bottom: 0px;
}

.socialMediaTag{
    color: white;
    font-weight: 600;
    border-radius: 3px;
    padding: 0px 17px 0px 17px;
    /* width: 80px; */
    height: 30px;
    /* margin-left: 5px; */
    /* margin-right: 5px; */
    display: flex;
    align-items: center;
}

.socialMetricTag{
    border: 1px solid rgb(230, 230, 230);
    background-color: rgb(250, 250, 250);
    color: #252525;
    text-align: center;
    font-weight: 700;
    border-radius: 3px;
    padding: 0px 0px 0px 0px;
    margin-left: 5px;
    margin-top: 10px;
}

.postJobPlatformsCreator .tiktokTag{
    margin-top: 10px;
}

.postJobPlatformsCreator .reelsTag{
    margin-top: 10px;
    margin-bottom: 5px;
}

.tiktokTag{
    /* background-color: black;   */
    background-image: linear-gradient(45deg, #25f4EE 0%,#000000 35%,#fe2c55 110%); 
}

.reelsTag{
    /* background-color: rgb(156, 28, 224); */
    background-image: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
}

.postJobType{
    display: flex;
}

.postJobPlatforms {
text-align: center;
}

.postJobPlatforms{
    display: flex;
    margin-left: 3px;
    align-items: center;
}

.postJobPlatforms p{
    margin-bottom: 5px;
    line-height: 22px;
    margin-top: 13px;
}

.postJobDescription p{
    margin-top: 0px;
    margin-bottom: 0px;
    line-height: 22px;
}

.postJobRequirements p{
    margin-top: 0px;
    margin-bottom: 0px;
    line-height: 22px;
}

.postApplyButton {
    text-align: center;
    margin-top: 30px;
    margin-bottom: 10px;
}

.postApplyButton a{
    display: block;
    margin-top: 25px;
    margin-bottom: 5px;
        /* background-color: #ff5c2c; */
    /* background-color: #ff5c2c; */
    /* border: 1px solid #ff7913; */
    padding-top: 10px;
    padding-bottom: 10px;
    /* background-color: #ff7913; */    
    font-size: 16px;
    font-weight: 700;
    border-radius: 3px;
    text-decoration: none;
/* 
    color: #252525;
    background-color: rgb(225, 225, 225);
    border: 1px solid rgb(215, 215, 215); */

    color: white;
    background-color: #ff7913;
    border: 1px solid #ff7913;

    /* color: white;
    background-color: #5b5b5b;
    border: 1px solid rgb(215, 215, 215); */
    /* color: #252525; */
}

.postApplyButton:hover {
cursor: pointer;
}

.exampleVideoLink{
    text-decoration: none;
    color: black;
    /* color: #363636;  */
    color: rgb(5, 99, 193);
    /* text-decoration: underline;
    text-decoration-thickness: 1px;
    text-decoration-color: #ff7913; */
    font-weight: 600;
}

.postFiltersSmall{
    display: none;
}


@media screen and (max-width: 900px){
    .postJobType{
        display: inline;
    }
    .tiktokTag{
        margin-left: 0px;  
     }
     .postJobPlatforms p{
        margin-top: 3px;
    }
    .postJobPlatforms{
        margin-left: 0px;
    }
    .postCompensation{
        margin-bottom: 20px;
    }
    /* .boo{
        display: inline;
    } */
}


@media screen and (max-width: 750px){

    .postFilters select{
        width: 167px;
    }

    .postFilters input{
        width: 145px;
    }
    .postFilters button{
        width: 167px;
    }

    .postFiltersSmall{
        display: block;
    }
    .postFiltersLarge{
        display: none;
    }

    .postTitle{
        display: inline;
    }

    .influencerBioHandle h4{
        margin-top: 0px;
    }

    .followerDetails{
        margin-top: 15px;
    }
    
    .postRestaurantDescription{
        display: inline;
    }
    .boo{
        display: inline;
    }

    .postJobDetails{
        width: 100%;
        margin-left: 0px;
    }

    .postCompensation{
        margin-top: 25px;
        margin-bottom: 25px;
    }

    .postCompensationCreator{
        margin-top: 25px;
        margin-bottom: 10px;
    }
    .postTitle p{
        /* margin-top: 8px; */
        margin-top: 8px;
        margin-bottom: 13px;
    }

    .postRestaurantImage{
        width: 100%;
        /* max-width: 350px; */
        margin-top: 20px;
        /* background-color: yellow; */
        text-align: center;
        max-width: none;
    }
    
    .postRestaurantImage img{
        max-width: 300px;
        height: 170px;
        object-fit: cover;
        
    }

    .post h3{
        margin-bottom: 0px;
    }

    .postInner{
        padding-top: 25px;
        padding-bottom: 25px;
    }
    .postTitle p{

        margin-left: 0px;
        }

}