.successModalWhole{
    /* background-image: linear-gradient(45deg, #edaa1b 0%,#ff5c2c 50%,#ff8f2c 100%); */
    /* background-image: linear-gradient(45deg, #edaa1b 0%, #ff8f2c 50%, #ff5c2c 100%); */
    background-color: gray;
    background-color: white;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: inline;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    z-index: 200;
    padding-top: 60px;
    padding-left: calc(5%);
    padding-right: calc(5%);
}

.successModalWhole h2{
    width: 86%;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 15px;
    font-weight: 800;
    line-height: 38px;
}

.successModalInner{
    padding-top: 25px;
    padding-bottom: 25px;
    max-width: 400px;
    width: 86%;
    background-color: rgb(250, 250, 250) ;
    /* border: 2px solid #ff8f2c; */
    margin: auto;
    border-radius: 10px;
    margin-bottom: 30px;

}
.successModalInnerInner{
padding-left: calc(5% + 13px);
padding-right: calc(5% + 13px);
}

.successModalWhole p{
   font-size: 18px;
   width: 86%;
   margin: auto;
   margin-bottom: 30px;
   line-height: 25px;
}

.successModalInner img{
    /* width: 100%;
    max-width: 250px; */
    height: 250px;
}

.successModalWhole button{
    width: 86%;
    margin: auto;
    padding-top: 5px;
}


.applyButton{
    width: 86%;
    text-align: center;
    margin: auto;
}

.applyButton a{
    display: block;
    border-radius: 3px;
    border: none;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 15px;
    margin-bottom: 5px;
    padding-left: 15px;
    padding-right: 15px;
    font-size: 16px;
    font-weight: 700;
    text-decoration: none;
    /* width: 100%; */
    color: #252525;
    background-color: rgb(225, 225, 225);
}

.successModalWhole a{
    padding-top: 12px;
    padding-bottom: 12px;
    max-width: 400px;
    margin: auto;
    /* width: 86%; */
}


@media screen and (max-width: 500px){
    .successModalInner img{
        /* width: 100%;
        max-width: 250px; */
        height: 200px;
    }
}