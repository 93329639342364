.about{
    background-image: linear-gradient(45deg, #edaa1b 0%, #ff8f2c 50%, #ff5c2c 100%); 
    
    /* height: 90vh; */
}

.about h1{
    margin: 0;
    text-align: left;
    font-size: 38px;
    line-height: 48px;
    /* padding-top: 100px; */
    width: 550px;
    font-weight: 800;
    /* background-color: yellow; */
}

.about p{
    text-align: left;
    /* line-height: 22px; */
    font-size: 18px;
    line-height: 25px;
    width: 550px;
    margin-top: 20px;
    /* background-color: green; */
}

.aboutButtons{
    margin-top: 45px;
    text-align: left;
}

.aboutInner{
    padding-left: calc(5% + 13px);
    padding-right: calc(5% + 13px);
    /* display: flex; */

    /* background-color: green; */
    
    width: 78%;
    margin: auto;
    padding-bottom: 15px;
}

.aboutInnerInner{
    display: flex;
    vertical-align: middle;
    min-height: 500px;
    align-items: center;
}

.aboutInner .postsIntroForm{
    width: 100%;
    max-width: 500px;
    margin-left: 0px;
    /* margin-top: -30px; */
    padding-bottom: 70px;
}

.aboutText{
    margin-top: -30px;
}

.aboutGraphic {
    /* background-color: pink; */
    margin-top: -20px;
    width: 100%;
    text-align: right;

}

.aboutGraphic img{
    width: 370px;
    text-align: right;
    padding-right: 20px;
}

.about .postsIntroForm p{
    margin-top: -50px;
}

@media screen and (max-width: 1200px){
    .about h1{
        width: 400px;
    }
    .about p{
        max-width: 400px;
    }
    .aboutGraphic img{
        padding-right: 0px;
    } 
}

/* @media screen and (max-width: 1200px){
    .about h1{
        width: 400px;
    }
    .about p{
        max-width: 400px;
    }
} */

@media screen and (max-width: 950px){
    .about h1{
        max-width: 300px;
    }
    .about p{
        max-width: 300px;
    }
    .aboutInner{
        width: 82%;
        padding-top: 25px;
    }
    /* .aboutGraphic img{
        max-width: 350px;

    } */
    .about .postsIntroForm p{
        margin-top: 0px;
    }
}



@media screen and (max-width: 750px){
    .about{
        min-height: 750px;
        /* height: 90vh; */
    }
    .about h1{
        margin: auto;
        text-align: center;
        max-width: none;
        width: 100%;
        padding-top: 50px;
        max-width: 450px;
        font-size: 34px;
        line-height: 45px;    
        
    }
    .about p{
        margin: auto;
        text-align: center;
        width: 100%;
        max-width: none;
        max-width: 550px;
        margin-top: 30px;
    }
    .aboutButtons{
        text-align: center;
    }
    .aboutInnerInner{
        display: block;
    }
    .aboutText{
        margin-top: 0px;
    }
    .aboutGraphic {
        margin-top: 0px;
    
    }
    .aboutGraphic img{
        max-width: 330px;
        width: 80%;
        margin-top: 40px;
        padding-bottom: 30px;
    }
    .aboutGraphic {
        text-align: center;
        padding-bottom: 40px;
    }
    .about .postsIntroForm p{
        margin-top: -20px;
    }

}

@media screen and (max-width: 400px){
    /* .about h1{
        padding-top: 50px;     
    } */
    .about p{
        margin: auto;
        text-align: center;
        width: 100%;
        max-width: none;
        max-width: 550px;
        margin-top: 30px;
    }
    .aboutButtons{
        text-align: center;
    }

}