.navbar{
    /* background-image: linear-gradient(to bottom, white, rgb(27, 37, 99)); */

    /* padding-right: 20px; */
    text-align: right;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    font-size: 16px;
    /* text-align: right; */
    background-image: linear-gradient(45deg, #edaa1b 0%,#ff5c2c 50%,#ff8f2c 100%); 
}

.navbarInner{
    /* background-color: pink; */
    width: 100%;
}

.chompLogo{
    width: 150px;
    float: left;
    padding-top: 0px;
}

.navResumeDiv{
    /* background-color: yellow; */
    float: right;
    display: flex;
    vertical-align: middle;
    align-items: center;
    width: max-content;
    /* margin-top: 15px; */
    /* width: calc(100% - 40px); */
}

.navLink{
    font-weight: 600;
}

.navLogoDiv{
    /* background-color: aquamarine; */
}

.addJobButton{
    /* width: 86%; */
    text-align: right;
    margin: auto;
    margin-left: 5px;
}

.addJobButton a{
    display: block;
    border-radius: 3px;
    border: none;
    padding-top: 10px;
    padding-bottom: 10px;
    /* margin-top: 15px; */
    /* margin-bottom: 5px; */
    padding-left: 15px;
    padding-right: 15px;
    font-size: 16px;
    font-weight: 700;
    /* color: white;
    background-color: #151515; */
    text-decoration: none;
    width: max-content;
    color: #252525;
    background-color: rgb(225, 225, 225);
}

.menuIcon{
    display: none;
    font-size: 30px;
    margin-left: 10px;
    
}

.navLink:hover{
    cursor: pointer;
}

.navLink{
   text-decoration: none;
   color: white;
   padding: 5px;
   margin-right: 10px;
   /* margin-right: 15px; */
}

.navLinkSmall{
    text-decoration: none;
    color: white;
    padding: 5px;
    font-weight: 600;
}

.navLinkSmallOuter{
    display: block;
    width: 170px;
    /* border: 1px solid red; */
    /* background-color: #edaa1b; */
    /* padding: 10px; */
    text-align: right;
    /* margin-bottom: 15px;
    margin-top: 10px; */
    margin-bottom: 0px;
    margin-top: 25px;   
}

.navLinkSmallOuter:first-child{

    /* background-color: rgb(131, 131, 131); */
    /* background-color: #f67d12; */
    /* background-image: linear-gradient(45deg, rgb(237, 170, 27) 0%, rgb(255, 143, 44) 100%); */
 
}

.navLinkSmallOuter a{
    margin-right: 20px;
    /* color: #252525; */
    color: #252525;
}

html {
    scroll-behavior: smooth;
  }

  /* .menuIcon{
    color: white;
  } */

@media screen and (max-width: 750px){
    .navLink{
        display: none;
    }
    .menuIcon{
        display: block;
        right: calc(30px);

    }
    /* .navbar .addJobButton{
        margin-right: calc(5% + 60px);
    } */
}


 @media screen and (max-width: 550px){
    .navbar{
        padding-left: 20px;
        padding-right: 20px;
    }
    .menuIcon{
        right: calc(20px);
    }
}