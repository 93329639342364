.influencerApply{
    /* background-color: rgba(0, 0, 0, .75);
    position: fixed;
    top: 0;
    bottom: 0px;
    left: 0;
    right: 0; */
    padding-top: 20px;
    padding-bottom: 50px;
    /* background-image: linear-gradient(45deg, #edaa1b 0%,#ff5c2c 50%,#ff8f2c 100%);  */
    /* background-image: linear-gradient(45deg, rgb(237, 170, 27) 0%, rgb(255, 143, 44) 50%, rgb(255, 92, 44) 100%); */
    /* background-color: #f3f3f3; */

}

.applicationJobDescription{
    text-align: center;
    width: 68%;
    line-height: 25px;
    margin: auto;
    margin-bottom: 25px;
    font-size: 18px;
}

.influencerApplyForm{
    padding-top: 25px;
    padding-bottom: 25px;
    width: 86%;
    background-color: white;
    margin: auto;
    border-radius: 10px;
    margin-bottom: 30px;
}

.applicationInputsLeft{
    width: 50%;
    margin-right: calc(2% + 10px);
}

.applicationInputsRight{
    width: 50%;
    margin-left: calc(2% + 10px);
}

.applicationInputs{
    display: flex;
}

.influencerApply h2{
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 28px;
    font-weight: 700;
    line-height: 35px;
}

.influencerApplyForm h4{
    margin-bottom: 10px;
    margin-top: 5px;
    line-height: 22px;
    color: #252525;
}

.influencerApplyForm form{
    padding-left: calc(5% + 13px);
    padding-right: calc(5% + 13px);
    text-align: left;
}

.influencerApply input{
    width: calc(100% - 20px);
    border-radius: 3px;
    border: none;
    color: black;
    padding-top: 8px;
    padding-bottom: 8px;
    margin-top: 0px;
    margin-bottom: 30px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 16px;
    /* background-color: rgb(243, 243, 243); */
    border: 1px solid rgb(230, 230, 230);
}

.influencerApply select{
    width: calc(100% + 2px);
    border-radius: 3px;
    border: none;
    color: black;
    padding-top: 8px;
    padding-bottom: 8px;
    margin-top: 0px;
    margin-bottom: 30px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 16px;
    /* background-color: rgb(243, 243, 243); */
    border: 1px solid rgb(230, 230, 230);
}

.influencerApply textarea{
    width: calc(100% - 20px);
    border-radius: 3px;
    border: none;
    color: black;
    padding-top: 8px;
    padding-bottom: 8px;
    margin-top: 0px;
    margin-bottom: 27px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 16px;
    border: 1px solid rgb(230, 230, 230);
    height: 122px;
}

.influencerApply button{
    display: block;
    margin-top: 20px;
    margin-bottom: 5px;
    border: 1px solid #ff7913;
    /* border: 1px solid #ff8f2c; */
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    /* padding-left: 45%;
    padding-right: 45%; */
    /* background-color: #ff5c2c; */
    /* background-color: #ff5c2c; */
    background-color: #ff7913;
    color: white;
    font-size: 16px;
    font-weight: 700;
    border-radius: 3px;
    text-decoration: none;
    color: white;
}

/* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 34px;
    margin-bottom: 20px;
  }
  
  /* Hide default HTML checkbox */
  .switch input {display:none;}
  
  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgb(225, 225, 225);
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 5px;
    width: 100%;
    margin: auto;
    /* border: 1px solid rgb(230, 230, 230); */
   
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 50%;
    right: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 5px;
    /* border: 1px solid rgb(230, 230, 230); */

  }
  
  input:checked + .slider {
    /* background-color: rgb(250, 250, 250); */
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #ccc;
  }
  
  input:checked + .slider:before {
    /* -webkit-transform: translateX(calc(150% - 8px));
    -ms-transform:  translateX(calc(150% - 8px));
    transform:  translateX(calc(150% - 8px)); */
    -webkit-transform: translateX(calc(-100% + 8px));
    -ms-transform:  translateX(calc(-100% + 8px));
    transform:  translateX(calc(-100% + 8px));
  }
  
  /* Rounded sliders */
  /* .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  } */

  .on{
  display: none;
}

.on{
  color: #252525;
  font-weight: 600;
  vertical-align: middle;
  align-items: center;
  position: absolute;
  top: 7px;
  right: calc(25% - 40px);
}

.off
{
      color: #252525;
  font-weight: 600;
  vertical-align: middle;
  align-items: center;
  position: absolute;
  top: 7px;
  left: calc(25% - 35px);

}

input:checked+ .slider .on
{display: block;}

input:checked + .slider .off
{display: none;}

/*--------- END --------*/

/* Rounded sliders */
/* .slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;} */




@media screen and (max-width: 800px){
    .applicationInputsLeft{
        width: 100%;
        margin-right: 0px;
    }
    
    .applicationInputsRight{
        width: 100%;
        margin-left: 0px;
    }
    
    .applicationInputs{
        display: inline;
    }
    .applicationJobDescription{
        text-align: center;
    }
}

@media screen and (max-width: 500px){
  .applicationJobDescription{
    width: 78%;
  }
  .influencerApplyForm form {
    padding-left: calc(5%);
    padding-right: calc(5%);
  }
  
}
