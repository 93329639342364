.footer{
    background-image: linear-gradient(45deg, #edaa1b 0%,#ff5c2c 50%,#ff8f2c 100%); 
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 15px;
    padding-bottom: 15px;
    display: flex;
    font-size: 16px;
    text-align: right;
    vertical-align: middle;
    align-items: center;
}

.footer .copyrightIcon{
    color: white;
    margin: auto;
}

.footer p{
    color: white;
    font-size: 16px;
    display: inline-block;
    margin-left: 10px;
    width: max-content;
}

.footerInnerHi{
    /* width: 300px; */
    /* width: max-content; */
    text-align: left;
    display: flex;
}

.footerInner{
    /* background-color: green; */
    width: 100%;
    /* padding-top: 13px; */
    text-align: right;
    vertical-align: middle;
    align-items: center;
}

.footer a{
    /* float:right; */
    color: white;
    text-decoration: none;
    font-weight: 500;
    /* margin-right: 20px; */
}

@media screen and (max-width: 550px){
    .footer{
        padding-left: 15px;
        padding-right: 10px;
    }
    .footer a{
        margin-right: 10px;
    }
}
